import React from 'react';
import { HelpArticle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';

const Content = () => {
  return (
    <div>
      <p>
        You can record sessions on a local server, such as <i>localhost</i>. There are, however,
        certain limitations you need to consider.
      </p>
      <p>
        To work properly, LiveSession needs <strong>public access</strong> to the website’s
        resources, such as CSS, fonts, and images. The app uses these elements to create a backup
        and display the recording correctly, even when the original files are deleted.{' '}
      </p>
      <p>
        Lack of access to these resources causes fallback. The LiveSession player will be trying to
        load the elements from the original source. The recording will be displayed correctly if
        you’re watching the session on a device with access to these resources (such as the computer
        you’ve used to record <i>localhost</i>).
      </p>
      <p>
        What’s more, some minor functions won’t be displayed from a local server, such as the{' '}
        <Code inline>:hover</Code> state of the cursor.
      </p>
      <p>
        To sum up, we suggest using LiveSession on a local server only for testing the tracking
        script.{' '}
      </p>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-check-if-my-tracking-script-works/',
    title: 'How to check if my tracking script works?',
    description: 'Check if your tracking code is installed correctly.',
  },
  {
    link: '/help/how-livesession-script-works/',
    title: 'How LiveSession script works?',
    description: 'Undarstand what our tracking code tracks.',
  },
];

export const frontmatter = {
  metaTitle: 'Does LiveSession works on internal servers (eg. localhost)?',
  metaDescription: 'Learn how you can test your script locally.',
  canonical: '/help/does-livesession-works-on-internal-servers/',
};

const Wrapped = HelpArticle(Content);

export default () => (
  <Wrapped related={related} section="Developer" title={frontmatter.metaTitle} {...frontmatter} />
);
